@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-weight: "Poppins", sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.spad {
  padding-top: 100px;
}
a {
  text-decoration: none;
  color: inherit;
}
.ul-services li {
  color: #1d1d1d;
  font-size: 16px;
}
@media (min-width: 567px) {
  .ul-services li {
    font-size: 16px;
  }
}
@media (min-width:768px) {
  .ul-services li {
    font-size: 20px;
  }
}
@media (min-width:991px) {
  .ul-services li {
    font-size: 24px;
  }
}

/* React Slick  */
.slick-list {
  margin: 0  12px;
}
.slick-slide > div {
  padding: 0 12px;
}

.slick-next:not(.best-seller-slick) {
  right:30px !important;
  width: 51px !important;
  height: 62px !important;

}

.slick-dots li.slick-active button::before {
  opacity: .75;
    color: black;
    content: "";
    width: 24px;
    height: 8px;
    background: #6E2E02;
    margin-top: 5px;
    /* line-height: 36px; */
    border-radius: 5px;
}

.slick-dots li {
  margin: 0 8px !important;
}
.slick-slide > div {
  padding:0
}
@media (min-width: 576px) {
  .slick-next:not(.best-seller-slick) {
    background: url('./assets/icons/nextArrow.png') center center no-repeat!important;
  }
  .slick-next:not(.best-seller-slick)::before  {
    display: none;
  }
}
/* line vertical */
@media (min-width: 900px) {
  .line {
    position: relative;
  }
  .line::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: rgb(17 16 16 / 6%);
    top: 0;
    left: 10px;
  }
}

/* animation */
.from-right {
  animation-name: right;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0px);
  }
}

.top-in {
  animation-name: topin;
  animation-duration: 1s;
}

@keyframes topin {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0px);
  }
}

/* edit height to image */
.img-cover {
  height: 190px;
  /* object-fit: cover; */
  border-radius: 8px;
}

.img-cover-shop {
  height: 160px;
  /* object-fit: cover; */
  border-radius: 8px;
}

.cart-img  {
  width: 118px;
  height: 118px;
  object-fit: cover;
  border-radius: 8px;
}

/* side cart */
.side-cart::-webkit-scrollbar {
  width: 4px;
}

.side-cart::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.side-cart::-webkit-scrollbar-thumb {
  background: #4a4242ba;
}

.side-cart::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* btn outline with gradient */
.main-btn-outline {
  border-style: solid;
  border-width: 2px;
  width: 100%;
  border-image: linear-gradient(114.83deg, #6E2E02 -19.62%, #CC8648 100%) 1;
  padding: 12px;
  background-color: transparent;
  background: linear-gradient(114.83deg, #6E2E02 -19.62%, #CC8648 100%);
  -webkit-background-clip: text;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  -webkit-text-fill-color: transparent;
  border-radius: 8px;
  box-sizing: border-box;
  position: static;
}

.main-btn-not-active {
  border:none;
  border-image: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.row .col {
  flex: 1 0 25%;
  max-width: 25%;
}


@media (min-width: 1200px) and (max-width: 1250px) {
  .image-navbar {
    width:150px;
  }
}

@media (min-width: 0px) and (max-width: 500px) {
  .image-navbar {
    width:85px;
  }
}


/* hide arrow from input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.slick-slide {
  padding: 0 30px; /* Adjust horizontal spacing between slides */
}

.slick-track {
  display: flex;
  align-items: center;
}








/* best seller slider */

.slider-container.bestseller-slider {
   margin: 0 24px; 
}

@media screen and (min-width:600px) {
  .bestseller-slider > .slick-slider{ 
    padding-right: 40px;
    padding-left: 40px;
  
  }
}


.bestseller-slider  .slick-slide{
padding-left: 8px;
padding-right: 8px;
 
}



.bestseller-slider .slick-next::before  {
  content: url(./assets/icons/chevron-right-solid\ \(2\).svg);
  display: block;
  width: 10px;
  height: 16px;
  
  filter:invert();
  -webkit-filter:invert();
}






.bestseller-slider .slick-prev::before  {
  display: block;
  width: 10px;
  height: 16px;
  content: url(./assets/icons/chevron-left-solid.svg);
  filter:invert();
  -webkit-filter:invert();

 
}



@media screen and (max-width: 600px){ 
  .slick-next.best-seller-slick ,.slick-prev.best-seller-slick{
    width: 30px;
    height: 30px;
}
}


@media screen and (min-width: 600px){ 
  .slick-next.best-seller-slick ,.slick-prev.best-seller-slick{
    width: 50px;
    height: 50px;
}
}
